.projects-grid {
  display: flex;
  flex-wrap: flex;
}

.projects-grid .projects-figure {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  mask-image: -webkit-radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.projects-grid .projects-figure img {
  width: 100%;
  transform: scale(1);
  transition: 0.25s ease-in-out;
}

.projects-grid .projects-figure:hover img {
  transform: scale(1.1);
}

.projects-grid .projects-figure figcaption {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0rem 2rem;
  color: #000;
  z-index: 1;
}

.projects-grid .overlay {
  background-color: #f48b88;
}

.projects-grid .overlay,
.projects-grid figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.45s ease-in-out;
}

.projects-grid .projects-figure:hover .overlay,
.projects-grid .projects-figure:hover figcaption {
  opacity: 1;
}

.projects-img-bed-baby {
  width: 100%;
  margin: 0 auto;
}

.projects-img-freitag {
  width: 80%;
  margin: 0 auto;
}

.badge {
  font-size: 100%;
  margin-right: 1em;
}
